import React from "react";
import { Typography, Alert } from "@mui/material";
import MBottomSheet from "components/shared/BottomSheet";
import MButton from "components/shared/MButton";
import ConfirmBankAccount from "components/kyc/ConfirmBankAccount";
import KycTextInput from "components/shared/KycTextInput";
import BaseLayout from "components/shared/BaseLayout";
import MHeading from "components/shared/MHeading";
import { useConfig } from "context/ConfigContext";

const BankDetails = ({
  account,
  setAccount,
  account2,
  setAccount2,
  ifscCode,
  setIfscCode,
  showBottomSheet,
  setShowBottomSheet,
  onContinueClick,
  onReenterClick,
  onConfirmClick,
  isLoading,
  accountInfo,
  bankErrors,
  setBankErrors,
  isBankEditable,
  validateIFSC,
}) => {
  const shouldShowErrorText =
    account.length > 0 &&
    (account2.length === account.length || account2.length >= account.length) &&
    account !== account2;

    const { config } = useConfig() || {};
    const note = config?.messages?.add_bank?.note ?? "";
    const subtitle = config?.messages?.add_bank?.subtitle ?? "This account will be used for investments and withdrawals";
    
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div id="body">
          <MHeading
            heading="Enter bank details"
            subHeading={subtitle}
          />
          <Typography variant="caption">{"Account Number"}</Typography>
          <KycTextInput
            placeholder={"xxxxxxxxxxxx"}
            text={account}
            isEditable={isBankEditable}
            setText={(text) => {
              setAccount(text);
              setBankErrors("");
            }}
            keyboardType="number"
            errorText={
              shouldShowErrorText ? "Account numbers do not match" : null
            }
          />
          <Typography variant="caption">{"Re-enter Account Number"}</Typography>
          <KycTextInput
            placeholder={"xxxxxxxxxxxx"}
            text={account2}
            isEditable={isBankEditable}
            setText={(text) => {
              setAccount2(text);
              setBankErrors("");
            }}
            keyboardType="number"
          />
          <Typography variant="caption">{"Bank IFSC"}</Typography>
          <KycTextInput
            placeholder={"xxxxxxxxxxxx"}
            text={ifscCode}
            isEditable={isBankEditable}
            setText={(text) => {
              setIfscCode(text);
              setBankErrors("");
            }}
            maxLength={11}
            errorText={
              ifscCode?.length >= 11 && !validateIFSC()
                ? "Invalid IFSC code"
                : null
            }
          />
          {
            note && (
              <Typography variant="caption" paddingBottom={2}>NOTE: {note}</Typography>
            )
          }
          {bankErrors && (
            <div style={{ marginBottom: 10 }}>
              <Alert severity="error">
                <Typography variant="alert">{bankErrors}</Typography>
              </Alert>
            </div>
          )}
        </div>
      }
      footer={
        <div id="footer">
          <MButton
            disabled={
              !(
                account &&
                account2 &&
                ifscCode &&
                account === account2 &&
                validateIFSC()
              )
            }
            onPress={onContinueClick}
            text={"Confirm"}
            isPurpleButton
            size="medium"
            loading={!showBottomSheet && isLoading}
          />
        </div>
      }
      bottomSheet={
        <MBottomSheet
          isVisible={showBottomSheet}
          close={() => setShowBottomSheet(false)}
          title="Bank Account Details"
        >
          <ConfirmBankAccount
            onReenterClick={onReenterClick}
            onConfirmClick={onConfirmClick}
            isLoading={showBottomSheet && isLoading}
            accountInfo={accountInfo}
          />
        </MBottomSheet>
      }
    />
  );
};

export default BankDetails;
